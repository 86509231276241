import React from 'react';
import Helmet from 'react-helmet';
import Sidebar from '../components/Sidebar';
import {graphql} from 'gatsby';
import CategoryTemplateDetails from '../components/CategoryTemplateDetails';
import Layout from '../components/Layout';

class CategoryTemplate extends React.Component {
    render() {
        const {title} = this.props.data.site.siteMetadata;
        const {category} = this.props.pathContext;

        return (
            <Layout>
                <div>
                    <Helmet title={`${category} - ${title}`}/>
                    <Sidebar {...this.props} />
                    <CategoryTemplateDetails {...this.props} />
                </div>
            </Layout>
        );
    }
}

export default CategoryTemplate;

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          facebook
          meetup
          xing
          instagram
        }
      }
    }
    allMarkdownRemark(
        limit: 1000,
        filter: { frontmatter: { category: { eq: $category }, layout: { eq: "post" }, draft: { ne: true } } },
        sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
        timeToRead
          fields {
            slug
            categorySlug
            tagSlugs
          }
          frontmatter {
            title
            date
            category
            description
            tags
            image {
                childImageSharp {
                    resize(quality: 100, width: 800) {
                        src
                    }
                }
            }
          }
        }
      }
    }
  }
`;
