import React from 'react';
import Post from '../Post';

class CategoryTemplateDetails extends React.Component {
  render() {
    const items = [];
    const { category } = this.props.pathContext;

    const posts = this.props.data.allMarkdownRemark.edges;
    posts.forEach((post) => {
        items.push(<Post data={post} key={post.node.fields.slug} />);
    });

    return (
      <div className="content">
          <h1 className="page__title">
              <span className="fixed-padding-left-increased white-text-grey-bg">
                  Posts zum Thema "{category}"
              </span>
          </h1>
        <div className="content__inner">
                {items}
        </div>
      </div>
    );
  }
}

export default CategoryTemplateDetails;
